
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    const hash = window.location.hash
    const jumplink = hash.split('#')[1]
    if (hash && jumplink === this.data.jumplink) {
      this.$el.scrollIntoView({ behavior: 'smooth' })
    }
  },
}
